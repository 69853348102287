// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails Application')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'



import common from './common';
import HomeIndex from '../components/home/index.vue'
import Developer from '../components/home/developer.vue'
import Guide from '../components/home/guide.vue'
import GuideListItem from '../components/home/guide_list_item.vue'
import NoticeIndex from '../components/notices/index.vue'
import GlobalNavHeader from '../components/home/global_nav_header.vue';
import DownloadBtn from '../components/home/download_btn.vue';
import CoffeeFooter from '../components/home/coffee_footer.vue';

import Rails from '@rails/ujs'
import axios from "axios";
window.common = common;

import 'bootstrap/dist/js/bootstrap.bundle.min';

import 'normalize.css/normalize.css';
import './application.scss';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'chartkick/highcharts';

common.setupAxios(axios);

Rails.start();

document.addEventListener('DOMContentLoaded', () => {
    common.mountComponent('#home-index', HomeIndex);
    common.mountComponent('#notice-index', NoticeIndex);
    common.mountComponent('#developer', Developer);
    common.mountComponent('#guide', Guide);
    common.mountComponent('#guide-list-item', GuideListItem);
    common.mountComponent('#global-nav-header', GlobalNavHeader);
    common.mountComponent('#download-btn', DownloadBtn);
    common.mountComponent('#coffee-footer', CoffeeFooter);
});