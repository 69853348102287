<template>
  <div id="developer">
    <global-nav-header :is-logged-in="isLoggedIn"/>
    <div class="content-wrapper">
      <h1>안녕하세요, 커피한잔 개발자입니다.</h1>
      <p>
        저는 이전에 네이버, 카카오, 여러 스타트업을 다니며 카카오톡, N드라이브, 왓챠플레이 등 유명한 프로그램들을 많이 만들었어요.<br>
        커피한잔은 개인 시간을 들여서 저 혼자 만드는 서비스에요.<br>
      </p>
      <p>
        많은 소개팅 회사들이 여성 회원들의 프로필을 (<a href="https://withcoffee.app/notices/14">온갖 속임수를 써서</a>) 어떻게든 남자 회원들에게 노출시켜서 돈을 버는데요.<br>
        이런 속임수를 사용하지 않고, 여러분들이 좋은 사람을 만날 수 있게 하는 데에만 제 노력을 기울여볼게요.<br>
        여러분들도 상대방에게 예의를 갖추고 서비스를 사용 부탁드려요.
      </p>
      <p>
        현재까지 커피한잔으로 <a href="https://withcoffee.app/notices/29">결혼한</a> 커플이 (연락 받은 경우만) <b>40 커플</b>이 있어요.<br>
        커플이 된 숫자가 아니라 결혼을 한 숫자가요!<br>
        생각해보면 살면서 누군가를 결혼으로 맺어주는 경험이 1번 있을까 말까 한데요,<br>
        저는 어쩌면 앞으로 100번은 넘게 할 수 있지 않을까...<br>
        이렇게 생각해보면 소개팅 서비스를 운영하는 것도 참 즐겁고 보람 있는 일입니다.
      </p>
      <p>
        응원, 제안 혹은 다른 하시고 싶은 이야기가 있으면 언제든지 <a href="mailto:withcoffee.me@gmail.com">여기</a>로 연락해주세요.<br>
        결혼하시는 분들은 반드시 연락주세요!
      </p>
      <h5>
        <i class="fas fa-external-link-alt"></i>
        <a href="https://jeho.page/about?utm_source=withcoffee&utm_medium=referral&utm_campaign=v3">개발자 블로그 방문하기</a>
      </h5>
    </div>
  </div>
</template>

<script>
  import GlobalNavHeader from "./global_nav_header.vue";
  export default {
    components: {GlobalNavHeader},
    data() {
      return {
      }
    },
    props: {
      isLoggedIn: {
        type: Boolean,
        default: false
      },
    },
    mounted() {
    },
    beforeMount() {
    },
    methods: {
    }
  }
</script>

<style scoped lang="scss">
  @import "../../stylesheets/common";

  #developer {
    .content-wrapper {
      padding: 0 25px;
      max-width: $desktop-max-width;
      margin: 20px auto;
      text-align: left;
      word-break: keep-all;
    }

    h1 {
      font-size: 25px;
    }
  }
</style>